import {request} from './index'

// ПОлучаем ядро
import axios from 'axios'
import {generateFormData} from '../common/utils'

export const fetchCoreBitrix = async () => {
    try {
        const response = await axios.get('/api/bitrix')
        return response.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

//получаем статику
export const fetchStatic = async () => {
    try {
        const response = await request('GET', '/api/main/static')
        return response.data
    } catch (err) {
        console.log(err)
    }
}

//предложения городов по запросу
export const fetchCity = async (value: string) => {
    try {
        const response = await axios.get('/api/main/location', {
            params: {
                city: value
            }
        })
        return response.data
    } catch (e) {
        console.log(e)
        throw e
    }
}

//установка локации
export const setLocation = async (value: string) => {
    try {
        const response = await axios.get('/api/main/setLocation', {
            params: {
                locationId: value
            }
        })
        return response.data
    } catch (e) {
        console.log(e)
        throw e
    }
}

export const searchCityGeo = async (lat: number, lon: number) => {
    const formData = generateFormData({lat, lon})
    try {
        const response = await axios.post('/api/main/geo', formData)
        return response.data.data
    } catch (e) {
        console.log(e)
    }
}

//получаем статью
export const fetchArticle = async (code: string) => {
    try {
        const response = await axios.get('/api/main/article', {
            params: {
                code: code
            }
        })
        return response.data
    } catch (e) {
        console.log(e)
    }
}
