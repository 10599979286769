import React, {FC, useContext} from 'react'
import styles from '../Search.module.scss'
import DetailsList from './Details/DetailsList'
import Preloader from '../../../../Preloader/Preloader'
import {observer} from 'mobx-react'
import StoreContext from '../../../../../stores/StoreContext'
import Backdrop from '../../../../Backdrop/Backdrop'

type AutocompleteResultsProps = {
	close: () => void,
	query: string
}

const AutocompleteResults:FC<AutocompleteResultsProps> = observer(({close, query}) => {
	const stores = useContext(StoreContext)
	return (
		<>
			<div className={`${styles.search__result} ${styles.searchResultMin}`}>
				{!stores?.searchStore.detailsData.loading
					? <DetailsList store={stores?.searchStore} close={close} query={query}/>
					: <Preloader/>}
				{/*{!stores?.searchStore.categoriesData.loading*/}
				{/*	? <Categories store={stores?.searchStore}/>*/}
				{/*	: <Preloader/>}*/}
			</div>
			<Backdrop handleClick={close} activeClass='active-search'/>
		</>
	)
})

export default AutocompleteResults