import React from 'react'
import style from './PrivacyPage.module.scss'
import data from './privacy.json'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'

const PrivacyPage = () => {

    const breadCrumbItems = [
        {
            'title': 'Главная',
            'path': '/'
        },
        {
            'title': 'Политика конфиденциальности',
            'path': '/privacy'
        }
    ]

    return (
        <div className="container" style={{maxWidth: 1440, color: '#fff'}}>
            <div className={style.breadcrumbs}>
                <Breadcrumbs items={breadCrumbItems}/>
            </div>
            <h1 className={style.title}>Privacy policy</h1>
            <div className={style.list}>
                {
                    data.map(({title, children}, id) => {
                        return (
                            <div>
                                <h4 className={style.list__tilte}>{`${id + 1} ${title}`}</h4>
                                {
                                    children.map(({title, children}, idTitle) => {
                                        return (
                                            <>
                                                <p className={style.list__item}>
                                                    <span
                                                        className={style.list__number}>{`${id + 1}.${idTitle + 1}`}</span>
                                                    <span>{title}</span>
                                                </p>
                                                {
                                                    children?.map(({title}, idTitle2) => (
                                                        <p className={style.list__item}>
                                                            <span
                                                                className={style.list__number}>{`${id + 1}.${idTitle + 1}.${idTitle2 + 1}`}</span>
                                                            <span>{title}</span>
                                                        </p>))
                                                }
                                            </>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default PrivacyPage