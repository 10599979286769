import React, {Dispatch, useContext} from 'react'
import {Link} from 'react-router-dom'
import Badge from '../../../../Badge/Badge'
import IconCart from '../../../../Svg/IconCart'
import IconUser from '../../../../Svg/IconUser'
import IconArrow from '../../../../Svg/IconArrow'
import {observer} from 'mobx-react'
import StoreContext from '../../../../../stores/StoreContext'
import UserPopover from './UserPopover'
import {useTranslation} from 'react-i18next'

type UserMenuProps = {
	menuUserShowing: boolean
	setMenuUserShowing: Dispatch<boolean>
}

const UserMenu = observer(({menuUserShowing, setMenuUserShowing}: UserMenuProps) => {
	const stores = useContext(StoreContext)
	const {t, i18n} = useTranslation()
	return (
		<>
			{stores &&
				<>
					{/*<Link to={'#'} className={`${styles['header__interaction-item']} ${styles.chats}`}>*/}
					{/*	<IconChat className={styles.chats__icon}/>*/}
					{/*	<Badge number={1} offset={{left: '50%'}}/>*/}
					{/*</Link>*/}

					{stores?.personalStore.role === 'client' &&
						<Link to='/cart/' className='header__interaction-item basket'>
							<IconCart className='basket__icon' />
							<Badge number={stores?.cartStore.productsInCartCount} offset={{left: '50%'}}/>
						</Link>}

					<div
						className='header__interaction-item user'
						onMouseOver={(e) => setMenuUserShowing(true)}>
						{!stores?.personalStore.authorized ?
							<Link to='/auth/' className='user__link'>
								<IconUser className='user__icon'/>
								<div className='user__text'>{t('header.signIn')}</div>
							</Link>
							:
							<div className='user__link'>
								<IconUser className='user__icon'/>
								<IconArrow className='user__arrow'/>
							</div>
						}

						{stores?.personalStore.authorized &&
							<>
								{menuUserShowing && <UserPopover/>}
							</>
						}
					</div>
					<div style={{marginLeft: 10, cursor: 'pointer'}} onClick={() => i18n.changeLanguage('en')}>En</div>
					<div style={{marginLeft: 10, cursor: 'pointer'}} onClick={() => i18n.changeLanguage('ru')}>Ru</div>
				</>
			}
		</>
	)
})

export default UserMenu