import {makeAutoObservable, toJS} from 'mobx'
import {fetchOrders, fetchOrdersFilter} from '../api/order'
import debounce from '@material-ui/core/utils/debounce'

enum Sort {
    none,
    ascend,
    descend
}

class OrdersStore {
    filterOpen = false
    searchbarType = 'order'
    searchQuery = ''
    supplySort = Sort.none
    dataTable = {
        settings: {
            desktop: [''],
            mobile: ['']
        },
        nav: {
            count: 0,
            page: 0,
            size: 0,
        },
        rows: [],
        loading: true
    }
    errors = []
    filters: any = {
        for: {
            active: 'order',
            options: new Map<string, string>([
                ['order', 'Номер заказа'],
                ['detail', 'Деталь']
            ])
        },
        search: {
          active: 'order',
          options: new Map<string, string>([
              ['order', 'Искать номер заказа'],
              ['detail', 'Искать деталь']
          ])
        },
        supplyDateRange: {
            start: undefined,
            end: undefined,
        },
        creationDateRange: {
            start: undefined,
            end: undefined,
        },
        status: {
            active: 0 as number | string,
            options: []
        },
        checkboxes: [],
        author: {
            active: 0,
            options: []
        },
        loaded: false,
        loading: false,
        order: '',
        part: ''
    }
    paginationSize = 20

    constructor() {
        makeAutoObservable(this)
    }

    get paginationSelect() {
        const options = ['5', '20']
        const perPage = options.findIndex(item => parseInt(item) === this.paginationSize)
        return {
            perPage,
            options
        }
    }

    get foundDetails() {
        return this.dataTable.loading ? 'подсчёт...'
            :
            this.dataTable.nav.count
    }

    get filtersToSend() {
        let delivery_id = [] as any[]
        this.filters.checkboxes.forEach((item: {label: string, checked: boolean, name: string | number}) => {
                if (item.checked) {
                    delivery_id.push(item.name)
                }
            }
        )
        return {
            insert_date_from: this.filters.creationDateRange.start,
            insert_date_to: this.filters.supplyDateRange.end,
            // delivery_date_from: this.filters.supplyDateRange.start,
            // delivery_date_to: this.filters.creationDateRange.end,
            status_id: this.filters.status.active,
            delivery_id,
            user_id: this.filters.author.active,
            order: this.searchbarType === 'order' ? this.searchQuery : '',
            part: this.searchbarType === 'detail' ? this.searchQuery : ''
        }
    }

    clearDataTable = () => {
        this.dataTable = {
            settings: {
                desktop: [''],
                mobile: ['']
            },
            nav: {
                count: 0,
                page: 0,
                size: 0,
            },
            rows: [],
            loading: true
        }
    }

    applyFilterDebounced = debounce(async () => {
        await this.getTable(1)
    }, 500)

    formattedFilters = (filters: any) => {
        return {
            status: {
                active: Object.keys(filters.status_id)[0],
                options: new Map(Object.entries(filters.status_id))
            },
            author: {
                active: Object.keys(filters.user_id)[0],
                options: new Map(Object.entries(filters.user_id))
            },
            supplyDateRange: {
                currentDate: new Date(),
                start: new Date(filters.delivery_date_from),
                end: new Date(filters.delivery_date_to)
            },
            creationDateRange: {
                currentDate: new Date(),
                start: new Date(filters.insert_date_from),
                end: new Date(filters.insert_date_to)
            },
            checkboxes: Object.entries(filters.delivery_id).map((value: any) => {
                return {label: value[1], checked: true, name: value[0]}
            })
        }
    }

    getTable = async (page?: number) => {
        this.errors = []
        this.dataTable.loading = true
        if (!this.filters.loaded) {
            await this.getFilter()
        }
        const filter = this.filtersToSend
        fetchOrders(page, this.paginationSize, filter)
            .then(res => {
                if (res.data) {
                    this.dataTable = res.data
                }
                else {
                    this.clearDataTable()
                    this.errors = res.errors
                }
                this.dataTable.loading = false
            })
            .catch(err => console.log(err))
    }

    getFilter = async () => {
        this.filters.loading = true
        fetchOrdersFilter()
            .then(r => {
                console.log(r)
                this.filters = {...this.filters, ...this.formattedFilters(r)}
                this.filters.loading = false
                this.filters.loaded = true
            })
            .catch(err => console.log(err))
    }

    toggleSupplySort = () => {
        if (this.supplySort < 2)
            this.supplySort++
        else
            this.supplySort = 0
    }

    toggleFilter = () => {
        this.filterOpen = !this.filterOpen
    }

    setCreationDateRange = (date: any) => {
        if (date) {
            this.filters.creationDateRange.start = date[0]
            this.filters.creationDateRange.end = date[1]
        }
        else {
            this.filters.creationDateRange.start = undefined
            this.filters.creationDateRange.end = undefined
        }
        this.applyFilterDebounced()
    }

    setSupplyDateRange = (date: any) => {
        console.log(date)
        if (date) {
            this.filters.supplyDateRange.start = date[0]
            this.filters.supplyDateRange.end = date[1]
        }
        else {
            this.filters.supplyDateRange.start = undefined
            this.filters.supplyDateRange.end = undefined
        }
        this.applyFilterDebounced()
    }

    setStatus = (v: any) => {
        this.filters.status.active = v
        this.applyFilterDebounced()
    }

    setAuthor = (v: any) => {
        this.filters.author.active = v
        this.applyFilterDebounced()
    }

    setSearchQuery = (query: string) => {
        this.searchQuery = query
        this.applyFilterDebounced()
    }

    toggleCheckbox = (name: string, checked: boolean) => {
        const index = this.filters.checkboxes.findIndex((el: any) => el.name === name)
        this.filters.checkboxes[index].checked = checked
        console.log(toJS(this.filters))
        this.applyFilterDebounced()
    }

    toggleFilterTab = (key: string) => {
        let previousSearchbarType = this.searchbarType
        this.filters.for.active = key
        this.filters.search.active = key
        this.searchbarType = key

        if ((previousSearchbarType !== key) && (this.searchQuery !== '')) {
            this.applyFilterDebounced()
        }
    }

}



export default OrdersStore
