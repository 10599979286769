import React from 'react'
import {Link} from 'react-router-dom'

import style from './Breadcrumbs.module.scss'
import ErrorBoundary from '../ErrorBoundary'

type BreadcrumbsProps = {
    items: { title: string, path: string }[]
}

const Breadcrumbs = ({items}: BreadcrumbsProps) => {
    return (
        <ErrorBoundary>
            <ul className={style.breadcrumbs}>
                {items && items.map(({title, path}, index) => {
                        if (index < items.length - 1) {
                            return (
                                <li key={index} className={style.breadcrumbs__item}>
                                    <Link to={path} className={style.breadcrumbs__link}>{title}</Link>
                                </li>
                            )
                        } else {
                            return <li key={index} className={style.breadcrumbs__item}>{title}</li>
                        }
                    }
                )}
            </ul>
        </ErrorBoundary>
    )
}

export default Breadcrumbs
