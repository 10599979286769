import React from 'react'
import TopBar from './TopBar/TopBar'
import BottomBar from './BottomBar/BottomBar'
import DevPanel from '../DevPanel/DevPanel'
import MobileMenu from './MobileMenu/MobileMenu'
import ErrorBoundary from '../ErrorBoundary'

const Header = () => {
    return (
        <>
            {process.env.REACT_APP_STAGE === 'development' && <DevPanel/>}
            <ErrorBoundary><TopBar/></ErrorBoundary>
            {process.env.REACT_APP_VERSION === 'client' && <ErrorBoundary><BottomBar/></ErrorBoundary>}
            <ErrorBoundary><MobileMenu/></ErrorBoundary>
        </>
    )
}

export default Header
