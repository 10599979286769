// список найденых деталей в автокомплите
import axios from 'axios'

export const fetchSearchedDetails = async (q: string, page?: string, size?: number, fast?: 'Y' | 'N', table: 'Y' | 'N' = 'N') => {
	try {
		// const response = await axios.get('https://run.mocky.io/v3/75a4d1ee-74c7-46e5-81b4-ed7f8b128598')
		const response = await axios.get('/api/catalog/search', {
			params: {
				q: q,
				page: page,
				size: size,
				fast: fast,
				table: table
			}
		})
		return response.data
	} catch (err) {
		console.log(err)
		throw err
	}
}

// подробная информация о товаре, списки офферов по артикулу
export const fetchSearchResultsOffers = async (article: string, page?: number, size?: number, filter?: any, order?: string[], mod?: any) => {
	try {
		// const response = await axios.get('https://run.mocky.io/v3/eeae217b-f3c2-4071-98c4-55eae6a76661')
		const response = await axios.get('/api/catalog/detail', {
			params: {
				article: article,
				page: page,
				size: size,
				filter: filter,
				order: order,
				mod: mod
			}
		})
		return response.data
	} catch (err) {
		console.log(err)
		throw err
	}
}

// подробная информация о товаре, списки кроссов по артикулу
export const fetchSearchResultsCrosses = async (article: string, page?: number, size?: number, filter?: any, order?: string[], mod?: any) => {
	try {
		// const response = await axios.get('https://run.mocky.io/v3/eeae217b-f3c2-4071-98c4-55eae6a76661')
		const response = await axios.get('/api/catalog/cross', {
			params: {
				article: article,
				page: page,
				size: size,
				filter: filter,
				order: order,
				mod: mod
			}
		})
		return response.data
	} catch (err) {
		console.log(err)
		throw err
	}
}

// фильтры товаров по артиклю
export const fetchDetailsFilters = async (article: string) => {
	try {
		const response = await axios.get('/api/catalog/filter', {
			params: {
				article
			}
		})
		return response.data
	} catch (err) {
		console.log(err)
		throw err
	}
}