import React, {ChangeEvent, createContext, useCallback, useEffect, useRef, useState} from 'react'
import {debounce} from '@material-ui/core/utils'
import Autocomplete from './Autocomplete'
import styles from './City.module.scss'
import {fetchCity} from '../../api/main'
import {setCartProduct} from '../../api/basket'

type CitySelectProps = {
    selectedHandle: (bx_code: string, value: string) => void
    changeHandle?: (bx_code: string) => void
    className?: string
    label?: boolean
    labelGrey?: string
    fullWidth?: boolean
    defaultValue?: string
}

export const CitySelectContext = createContext<{
    focus: boolean
    suggestions: any[]
    onSelect: (cityObj: any) => void
    styles: any
}>({
    focus: false,
    suggestions: [],
    onSelect: (cityObj: any) => {
    },
    styles: styles
})

const CitySelect = ({
                        selectedHandle,
                        changeHandle,
                        className = '',
                        label = true,
                        labelGrey,
                        fullWidth = false,
                        defaultValue
                    }: CitySelectProps) => {
    const [focus, setFocus] = useState<boolean>(false)
    const [suggestions, setSuggestions] = useState<any[]>([])
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (changeHandle) {
            changeHandle('')
        }
        if (e.target.value === '') {
            setSuggestions([])
        } else {
            setSuggestions([{value: 'Загрузка...'}])
            fetchCity(e.target.value)
                .then(r => {
                    if (r.data) {
                        console.log(r.data)
                        setSuggestions(r.data)
                    } else {
                        setSuggestions([])
                    }
                })
        }
    }
    const ref = useRef<HTMLInputElement>(null)
    const onSelect = (cityObj: any) => {
        if (ref && ref.current) {
            ref.current.value = cityObj.value
            selectedHandle(cityObj.data.bx_code, cityObj.value)
        }
    }

    const debounceSync = useCallback(
        debounce((event: any) => handleChange(event), 500),
        []
    )

    const [activeValue, setActiveValue] = useState(defaultValue)
    const [selectValue, setSelectValue] = useState(defaultValue)
    const handleChangeValue = (event: any) => {
        setActiveValue(event.target.value)
        debounceSync(event)
    }

    useEffect(() => {
        setActiveValue(selectValue)
    }, [selectValue])

    const rootEl = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const onClick = (e: any) => {
            if (rootEl.current) {
                rootEl.current.contains(e.target) || setActiveValue(selectValue)
            }
        }
        document.addEventListener('click', onClick)
        return () => document.removeEventListener('click', onClick)
    }, [])

    return (
        <CitySelectContext.Provider value={{focus, suggestions, onSelect: onSelect, styles}}>
            <div ref={rootEl}
                 className={`${className} ${styles.citySelect} ${focus ? styles.active : ''} ${fullWidth ? styles['citySelect_full-width'] : ''}`}>
                <div className="inputWrap">
                    {/*FIXME сделать одним \|/*/}
                    {label && <div className="inputWrap__label color--black">Город</div>}
                    {labelGrey && <div className="inputWrap__label">{labelGrey}</div>}
                    <input
                        ref={ref}
                        name="city"
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        onChange={handleChangeValue}
                        type="text"
                        placeholder="Введите название города"
                        autoComplete="off"
                        className="input"
                        value={activeValue}
                    />
                    {suggestions.length > 0 && <Autocomplete setSelectValue={setSelectValue}/>}
                </div>
            </div>
        </CitySelectContext.Provider>
    )
}

export default CitySelect