import axios from 'axios'

export const initDataOrder = async (data: any) => {
    try {
        const result = await axios.post('/api/order/init', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return result.data
    } catch (e) {
        console.log(e)
    }
}

export const makeOrder = async (data: any) => {
    try {
        const result = await axios.post('/api/order/make', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return result.data
    } catch (e) {
        console.log(e)
    }
}

export const fetchOrders = async (page?: number, size?: number, filter?: object) => {
    try {
        const result = await axios.get('/api/order/list', {
            params: {
                page,
                size,
                filter
            }
        })
        return result.data
    } catch (e) {
        console.log(e)
    }
}

export const fetchOrdersFilter = async () => {
    try {
        const result = await axios.get('/api/order/filter')
        return result.data.data
    }
    catch (e) {
        console.log(e)
    }
}

export const fetchOrder = async (id: string) => {
    try {
        const result = await axios.get('/api/order/detail', {
            params: {
                id
            }
        })
        return result.data
    }
    catch (e) {
        console.log(e)
    }
}