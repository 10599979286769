import {makeAutoObservable} from 'mobx'
import {fetchSellerOrder, sellerAllowOrderProcess} from '../api/sellerOrder'
import {replaceIsoDatesInString} from '../common/utils'
import {sellerConfirmOrder} from '../api/sellerOrder'

type TGeneralInfo = {
	label: string,
	info: {
		text: string,
		status?: string
	}
}[]

type dataRow = {
	uid: string,
	brand: {
		label: string,
		link: {
			text: string,
			url: string
		}
	},
	product: {
		label: string,
		add: {
			tooltip: string
		}
	},
	cnt: number,
	changeCntInput: {
		input: {
			text: string,
			disabled: boolean,
			error: string
		}
	},
	price: number,
	summ: number
}

type orderStatus = 'confirm' | 'partly-confirmed' | 'confirmed'

class SellerOrderStore {
	errors = []
	confirmError = null
	allowProcessResponse = {msg: '', success: false}
	processed = false

	generalInfo: TGeneralInfo = []

	dataTable = {
		loading: true,
		settings: {
			desktop: [''],
			mobile: ['']
		},
		totals: {} as {inorder: any, rejected: any, total: any},
		rows: []
	}
	payBtnLink = ''

	constructor() {
		makeAutoObservable(this)
	}

	parseGeneralInfo = (generalInfoObj: TGeneralInfo): TGeneralInfo => {
		return generalInfoObj.map(({label, info}) => {
			return {
				label,
				info: {
					text: replaceIsoDatesInString(info.text || ''),
					status: info.status
				}
			}
		})
	}


	clearDataTable = () => {
		this.dataTable = {
			settings: {
				desktop: [''],
				mobile: ['']
			},
			rows: [],
			totals: {} as {inorder: any, rejected: any, total: any},
			loading: true
		}
	}

	getOrder = async (id: string) => {
		this.errors = []
		this.confirmError = null
		this.allowProcessResponse = {msg: '', success: false}
		this.dataTable.loading = true
		fetchSellerOrder(id)
			.then(res => {
				console.log(res)
				if (res.data) {
					const {generalInfo, payBtn, processed, ...dataTable} = res.data
					this.generalInfo = this.parseGeneralInfo(generalInfo)
					this.payBtnLink = payBtn
					this.dataTable = dataTable
					this.processed = processed
				}
				else {
					this.clearDataTable()
					this.errors = res.errors
				}
				this.dataTable.loading = false
			})
			.catch(err => {
				console.log(err)
			})
	}

	confirmOrder = async (id: string, reject: boolean = false) => {
		this.confirmError = null
		const formData = new FormData()
		formData.append('fields[id]', id)
		this.dataTable.rows.forEach((product: any) => {
			formData.append(`fields[basket][${product.uid}][id]`, product.uid)
			formData.append(`fields[basket][${product.uid}][quantity]`, reject ? 0 : product.count.counter.value)
		})
		sellerConfirmOrder(formData)
			.then(r => {
				console.log(r)
				if (r.data) {
					this.getOrder(id)
				}
				else {
					this.confirmError = r.errors[0].message
					console.log(this.confirmError)
				}
			})
			.catch(err => console.log(err))
	}

	allowProcess = async (id: string) => {
		this.allowProcessResponse = {msg: '', success: false}
		if (process.env.REACT_APP_STAGE === 'development') {
			sellerAllowOrderProcess(id)
				.then(r => {
					if (r.data) {
						this.allowProcessResponse = {msg: 'allow process: success', success: true}
					}
					else {
						this.allowProcessResponse = {msg: 'allow process: error', success: false}
					}
				})
				.catch(err => this.allowProcessResponse = {msg: 'allow process: error', success: false})
		}
	}

	handleInput = (uid: string, input: string) => {
		const inputRowIndex = this.dataTable.rows.findIndex((el: any) => el.uid === uid)
	}
}

export default SellerOrderStore