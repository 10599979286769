import React, {useContext, useState} from 'react'
import {observer} from 'mobx-react'
import StoreContext from '../../../stores/StoreContext'
import IconLock from '../../Svg/IconLock'
import IconArrow from '../../Svg/IconArrow'
import IconCalendar from '../../Svg/IconCalendar'
import clsx from 'clsx'


const Company = observer(() => {
    const stores = useContext(StoreContext)
    const [menuOpen, setMenuOpen] = useState(false)
    return (
        <div className={clsx('menuMob__company', 'company', {open: menuOpen})}>
            <div className='company__title'>{stores?.personalStore?.personal?.['contragent']?.['name']}</div>
            <div
                className={`
                    company__total
                    ${parseFloat(stores?.personalStore?.contract?.['balance'] || '0') < 0 ? 'color--red' : 'color--white'}`}
                onClick={() => setMenuOpen(!menuOpen)}>
                <div className='company__total-text color--red'>
                    {stores?.personalStore?.contract?.['balance']} руб.
                </div>
                <IconArrow className='company__total-icon'/>
            </div>
            {!stores?.personalStore?.contract?.['active'] &&
                <div className='company__status color--yellow'>
                    <IconLock className='company__status-icon'/>
                    <div className='company__status-text'>Временно заблокировано</div>
                </div>}
            <div className='company__details'>
                <div className='company__details-item'>
                    <div className='company__details-title'>Договор</div>
                    <div className='company__select'>
                        &laquo;{stores?.personalStore?.contract?.['name']}&raquo;
                    </div>
                </div>
                <div className='company__details-item'>
                    <div className='company__details-delay'>
                        {stores?.personalStore?.contract?.['isCredit'] ?
                            <>
                                <IconCalendar className='company__details-icon'/>
                                <div className='company__details-text'>{stores?.personalStore?.contract?.['creditDays']} дней отсрочки</div>
                            </>
                            :
                            <div className='company__details-text'>Договор предоплаты</div>
                        }
                    </div>
                </div>
                {stores?.personalStore?.contract?.['isCredit'] ?
                    <>
                        <div className='company__details-item'>
                            <div className='company__details-title'>Кредит</div>
                            <div className='company__details-text'>{stores?.personalStore?.contract?.['creditSum']} руб.</div>
                        </div>
                        <div className='company__details-item'>
                            <div className='company__details-title'>Остаток</div>
                            <div className='company__details-text'>{parseFloat(stores?.personalStore?.contract?.['creditSum'] || '0') - parseFloat(stores?.personalStore?.contract?.['balance'] || '0')} руб.</div>
                        </div>
                        <div className='company__details-item'>
                            <div className={`'company__total-text' ${parseInt(stores?.personalStore?.contract?.['balance'] || '') < 0 ? 'color--red' : 'color--white'}`}>Итоговая задолженность</div>
                            <div className='company__details-text color--red'>{stores?.personalStore?.contract?.['balance']} руб.</div>
                        </div>
                    </>
                    :
                        <div className='company__details-item'>
                            <div className='company__details-title'>Сумма</div>
                            <div className='company__details-text'>{stores?.personalStore?.contract?.['creditSum']} руб.</div>
                        </div>
                }
            </div>
        </div>
    )
})

export default Company
