import {makeAutoObservable} from 'mobx'
import {initDataOrder} from '../api/order'

class CheckoutStore {

    initData: any = null
    loading = true
    error = ''

    constructor() {
        makeAutoObservable(this)
    }



    fetchInitData = async (data:any, bx_code?: string) => {
        let sendData:any = {}
        this.loading = true
        this.error = ''
        if (bx_code) {
            sendData = {
                contract: '',
                delivery: 1,
                payment: 1,
                region: '',
                regionCode: bx_code,
                address: '',
                consignee: {
                    inn: '',
                    company: '',
                    kpp: '',
                    name: '',
                    lastName: '',
                    firstName: '',
                    middleName: '',
                    phone: '',
                },
                signatory: {
                    position: '',
                    baseDocument: '',
                    firstName: '',
                    lastName: '',
                    middleName: ''
                }
            }
        } else sendData = data
        const result = await initDataOrder(sendData)
        if (result.errors.length) {
            return this.error = result.errors[0].message
        }
        this.initData = result.data
        this.loading = false
    }
}

export default CheckoutStore