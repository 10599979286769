import React, {useContext, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import Container from '../../../layouts/Container/Container'
import Logo from '../../Logo/Logo'
import Burger from './components/Burger'
import StoreContext from '../../../stores/StoreContext'
import Location from './components/Location/Location'
import UserMenu from './components/UserMenu/UserMenu'
import ReturnsMenu from './components/ReturnsMenu/ReturnsMenu'
import {observer} from 'mobx-react'
import {useTranslation} from 'react-i18next'
import clsx from 'clsx'

const TopBar = observer(() => {
    const stores = useContext(StoreContext)
    const [menuUserShowing, setMenuUserShowing] = useState(false)
    stores?.personalStore.setRole(process.env.REACT_APP_VERSION || 'client')
    const {t} = useTranslation()
    const location = useLocation()
    console.log(location.pathname)
    return (
        <header className='header'>
            <Container>
                <div className='header__top--flex'>
                    <Logo className='header__logo'/>

                    <div className='header__basic'>
                        {stores?.personalStore.role === 'client' &&
                            <>
                                <Link to='/catalog/' className='header__basic-item catalogs'>
                                    <div className='catalogs__icon'><span/></div>
                                    <div className='catalogs__text'>{t('header.catalogs')}</div>
                                </Link>
                                <Link to={'#'} className='header__basic-item'>{t('header.relatedProducts')}</Link>
                            </>}
                        <Location/>
                    </div>

                    {stores?.personalStore.authorized &&
                        <>
                            {stores?.personalStore.role === 'provider' &&
                                <>
                                    <div className='header__addit'>
                                        <Link
                                            to={`/${stores?.personalStore.urlUserRole}/orders`}
                                            className={clsx(
                                                'header__addit-item',
                                                {active: `/${stores?.personalStore.urlUserRole}/orders` === location.pathname})}>
                                            Заказы
                                        </Link>
                                        <Link
                                            to={`/${stores?.personalStore.urlUserRole}/shipment`}
                                            className={clsx(
                                                'header__addit-item',
                                                {active: `/${stores?.personalStore.urlUserRole}/shipment` === location.pathname})}>
                                            Отгрузка
                                        </Link>
                                        <Link
                                            to={`/${stores?.personalStore.urlUserRole}/documents/create`}
                                            className={clsx(
                                                'header__addit-item',
                                                {active: `/${stores?.personalStore.urlUserRole}/documents/create` === location.pathname})}>
                                            Загрузить документ
                                        </Link>
                                    </div>
                                    <div className='header__addit'>
                                        <Link
                                            to={`/${stores?.personalStore.urlUserRole}/documents`}
                                            className={clsx(
                                                'header__addit-item',
                                                {active: `/${stores?.personalStore.urlUserRole}/documents` === location.pathname})}>
                                            Документы
                                        </Link>
                                        <ReturnsMenu urlUserRole={stores?.personalStore.urlUserRole}/>
                                        <Link
                                            to={`/${stores?.personalStore.urlUserRole}/stats`}
                                            className={clsx(
                                                'header__addit-item',
                                                {active: `/${stores?.personalStore.urlUserRole}/stats` === location.pathname})}>
                                            Статистика
                                        </Link>
                                    </div>
                                </>
                                }

                            {stores?.personalStore.role === 'client' &&
                                <div className='header__addit'>
                                    <Link
                                        to={`/${stores?.personalStore.urlUserRole}/orders`}
                                        className={clsx(
                                            'header__addit-item',
                                            {active: `/${stores?.personalStore.urlUserRole}/orders` === location.pathname})}>
                                        Заказы
                                    </Link>
                                    <ReturnsMenu urlUserRole={stores?.personalStore.urlUserRole}/>
                                    <Link
                                        to={`/${stores?.personalStore.urlUserRole}/documents`}
                                        className={clsx(
                                            'header__addit-item',
                                            {active: `/${stores?.personalStore.urlUserRole}/documents` === location.pathname})}>
                                        Документы
                                    </Link>
                                </div>
                            }
                        </>}

                    <div className='header__interaction' onMouseLeave={(e) => setMenuUserShowing(false)}>
                        <UserMenu
                            menuUserShowing={menuUserShowing}
                            setMenuUserShowing={setMenuUserShowing}/>
                        <Burger/>
                    </div>
                </div>
            </Container>
        </header>
    )
})

export default TopBar
