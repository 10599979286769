import React from 'react'

import IconCar from '../../../Svg/IconCar'
import IconSupport from '../../../Svg/IconSupport'

import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

const ExtraSearchLinks = () => {
    const {t} = useTranslation()
    return (
        <div className='headerBottom__extra'>
            <div className='headerBottom__extra-item'>
                <IconCar className='headerBottom__extra-icon'/>
                <Link to='/search/' className='headerBottom__extra-text'>{t('header.searchByVin')}</Link>
            </div>
            <div className='headerBottom__extra-item'>
                <IconSupport className='headerBottom__extra-icon'/>
                <Link to='#' className='headerBottom__extra-text'>{t('header.assistance')}</Link>
            </div>
        </div>
    )
}

export default ExtraSearchLinks
