import React, {FC, FormEvent, useContext} from 'react'
import IconSearch from '../../../../Svg/IconSearch'
import {BtnPrimary} from '../../../../Btn/Btn'
import {StylesContext} from './Search'
import {useTranslation} from 'react-i18next'

type SearchInputProps = {
	onChange: (r: string) => void
	onSubmit: (e: FormEvent<HTMLFormElement>) => void
	ref: React.Ref<HTMLInputElement>
}

const SearchInput:FC<SearchInputProps> = React.forwardRef(({onChange, onSubmit}, ref) => {
	const styles = useContext(StylesContext)
	const {t} = useTranslation()
	return (
		<form className={styles.search__form} onSubmit={(e) => onSubmit(e)}>
			<IconSearch className={styles.search__icon}/>
			<input
				ref={ref}
				name='search'
				className={styles.search__input}
				type='search'
				placeholder={t('header.enterDetailNumber')}
				autoComplete='off'
				onChange={(e) => onChange(e.target.value)}/>
			<BtnPrimary type='submit' addClass={styles.search__btn}>{t('header.search')}</BtnPrimary>
		</form>
	)
})

export default SearchInput