import React from 'react'
import style from './Banner.module.scss'
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs'

const Banner = () => {

    const breadCrumbItems = [
        {
            'title': 'Главная',
            'path': '/'
        },
        {
            'title': 'О компании',
            'path': '/about'
        }
    ]

    return (
        <div className={style.banner}>
            <div className={style.breadcrumbs}>
                <Breadcrumbs items={breadCrumbItems}/>
            </div>
            <div className={`${style.wrapper} container`}>
                <div className={style.info}>
                    <h1 className={style.info__title}>Поставщикам</h1>
                    <div className={style.statistics}>
                        <div className={style.statistics__title}>Расширяйте свой бизнес<br/>
                            и продавайте <span className={style.textOutline}>вместе с нами</span></div>
                    </div>
                    <div className={style.statistics}>
                        <div className={style.statistics__description}>Приглашаем к сотрудничеству новых поставщиков запасных частей, масел и аксессуаров к автомобилям.<br/>
                            Развивайте ваш бизнес с нами в любом регионе страны.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner