import React from 'react'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import style from './DeliveryPage.module.scss'
import Payment from './components/Payment/Payment'
import Delivery from './components/Delivery/Delivery'

const DeliveryPage = () => {

    const breadCrumbItems = [
        {
            'title': 'Главная',
            'path': '/'
        },
        {
            'title': 'Оплата и доставка',
            'path': '/delivery'
        }
    ]

    return (
        <>
            <div style={{background: '#fff'}}>
                <div className="container" style={{maxWidth: 1440}}>
                    <Breadcrumbs items={breadCrumbItems}/>
                    <h1 className={style.title}>Оплата и доставка</h1>
                    <Payment/>
                </div>
            </div>
            <div className="container" style={{maxWidth: 1440}}>
                <Delivery/>
            </div>
        </>
    )
}

export default DeliveryPage