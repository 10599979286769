import React, {Suspense} from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'

import './scss/style.scss'
import './scss/recaptcha.scss'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import CookiesProvider from 'react-cookie/es6/CookiesProvider'

import './i18n'

ReactDOM.render(
    <React.StrictMode>
        <CookiesProvider>
            <Suspense fallback={<p>loading</p>}>
                <App/>
            </Suspense>
        </CookiesProvider>
    </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
