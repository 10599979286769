import React, {useContext} from 'react'
import {Virtuoso} from 'react-virtuoso'
import CityItem from './CityItem'
import {CitySelectContext} from './CitySelect'

const Autocomplete = ({setSelectValue}:{setSelectValue?: (x:string) => void}) => {
	const {suggestions, styles} = useContext(CitySelectContext)
	return (
		<div className={`${styles.citySelect__result} ${styles.searchResultMin}`}>
			<Virtuoso
				style={{height: '100%'}}
				data={suggestions}
				className={styles.citySelect__scroller}
				itemContent={(index, item) => <CityItem cityObj={item} setSelectValue={setSelectValue}/>}/>
				{/*<div>*/}
				{/*	{suggestions.map((item) => <div>{item.value}</div>)}*/}
				{/*</div>*/}
		</div>
	)
}

export default Autocomplete