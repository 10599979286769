import React from 'react'
import style from './Header.module.scss'
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs'

const Header = () => {
	const breadCrumbItems = [
		{
			'title': 'Главная',
			'path': '/'
		},
		{
			'title': 'О компании',
			'path': '/about'
		}
	]
	return (
		<div className={`${style.header} container`}>
			<div className={style.breadcrumbs}>
				<Breadcrumbs items={breadCrumbItems}/>
			</div>
			<h1>Возврат товаров</h1>
			<p>Вернуть и обменять товары заказанные в на нашем портале можно до 90 дней со дня получения товара. Срок возврата товара зависит от выбранных Вами условий при заказе.</p>
		</div>
	)
}

export default Header