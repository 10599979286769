import React, {useContext, useEffect} from 'react'
import {observer} from 'mobx-react'
import StoreContext from '../../../../stores/StoreContext'
import clsx from 'clsx'

const Burger = observer(() => {
	const stores = useContext(StoreContext)
	useEffect(() => {
		if (stores && stores.headerStore.mobileMenuOpened) {
			document.body.classList.add('lock')
		}
		else {
			document.body.classList.remove('lock')
		}
	})
	return (
		<>
		{stores &&
			<div
				className={clsx('header__interaction-item', 'burger', {active: stores.headerStore.mobileMenuOpened})}
				onClick={() => stores.headerStore.toggleMobileMenu()}>
				<span/>
				<span/>
				<span/>
			</div>}
		</>
	)
})

export default Burger