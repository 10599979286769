import React from 'react'
import style from './Return.module.scss'

const Return = () => {
	return (
		<div className={style.return}>
			<h1>Возврат товара осуществляется<br/> через личный кабинет покупателя</h1>
			<div className={style.return__stepper}>
				<div className={style.return__step}>
					<p className={style.return__stepLeft}>
						<p>Заполните заявку</p>
						<p className={style.gray}>Если не нарушена заводская упаковка, товар не был в употреблении, сохранен его товарный вид и потребительские свойства, но он вам не подходит.</p>
					</p>
					<div className={style.return__stepNumber}>
						<span>1</span>
					</div>
				</div>
				<div className={style.return__step}>
					<div className={style.return__stepNumber}>
						<span>2</span>
					</div>
					<p className={style.return__stepRight}>Верните товар в интернет-магазин, либо мы самостоятельно заберем его.</p>
				</div>
				<div className={style.return__step}>
					<p className={style.return__stepLeft}>
						<p>Пришлите заявление на возврат денежных средств в личном кабинете.</p>
						<p className={style.gray}>Либо мы зачислим средства на ваш счет на нашем сайте, вы сможете заказать любой другой товар, понравившийся Вам.</p>
					</p>
					<div className={`${style.return__stepNumber} ${style['return__stepNumber--done']}`}>
						<span/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Return