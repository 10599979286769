import React, { Component, ErrorInfo, ReactNode } from "react"
import axios from 'axios'

interface Props {
    children: ReactNode;
    showMsg: boolean
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    };
    static defaultProps: { showMsg: boolean }

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        try {
            axios.post('/api/logs/send', errorInfo, {
                headers: {"content-type": "application/json"}
            })
        } catch (err) {
            console.log(err)
        }
    }

    public render() {
        if (this.state.hasError) {
            return this.props.showMsg ? <h1>Что-то пошло не так =(</h1> : <></>
        }

        return this.props.children;
    }
}

ErrorBoundary.defaultProps = {
    showMsg: false
}

export default ErrorBoundary;