import React from 'react'
import style from './Faq.module.scss'
import img1 from './images/1.svg'
import img2 from './images/2.svg'

const Faq = () => {
	return (
		<div className={style.faq}>
			<div className={style.faq__row}>
				<div className={style.faq__question}>
					<img src={img1} alt=""/>
					<p>Если выявились проблемы с заказом</p>
				</div>
				<div className={style.faq__answer}>
					<p>Если до начала эксплуатации выяснилось, что товар поврежден, отличается от заказанного, утеряны потребительские свойства, или заказ доставлен не полностью, мы приложим все усилия чтобы устранить проблему в кратчайшие сроки.</p>
					<p>Сообщите нам  о проблемах с заказом, отправьте нам фото товара (весь функционал доступен Вам в личном кабинете)</p>
					<p>Как правило, некачественный товар у вас заберет курьер. Если в вашем регионе нет наших курьеров, менеджер поможем оформить возврат тем же способом, которым вы его получили за наш счет.</p>
					<p>Решение о возврате принимается в течение 2-х рабочих дней. В 95% случаев мы вернем денежные средства или обменяем товар не дожидаясь получения возврата магазином.</p>
				</div>
			</div>
			<div className={style.faq__row}>
				<div className={style.faq__question}>
					<img src={img2} alt=""/>
					<p>Проблемы с товаром выявились в процессе эксплуатации или установки</p>
				</div>
				<div className={style.faq__answer}>
					<p>Проблемы с товаром могут быть вызваны низким качеством, условиями эксплуатации, неправильной установкой. Возможно, в процессе установки выяснилось, что товар вам не подходит. Решением проблем подобного рода занимаются эксперты отдела рекламаций. Если в рамках интернет-магазина принять квалифицированное решение невозможно, мы обращаемся к производителю или его представителю.</p>
					<p>В случае выявления недостатка после установки запчастей нужно предоставить заказ наряд на установку запасной части с подробным описание выявленного недостатка в рекомендациях заказ наряда (в случае электроники - КОД ошибки). Обязательное указание информации в заказ наряде: Дата, VIN, пробег, наименование работ, артикул и бренд используемой запчасти.</p>
					<ol>
						<li>Оформите возврат на сайте, выбрав соответствующую причину. Приложите акт рекламации.</li>
						<li>Верните товар в интернет-магазин.</li>
						<li>По результатам экспертизы будет принято решение о возврате денежных средств.</li>
					</ol>
				</div>
			</div>
		</div>
	)
}

export default Faq