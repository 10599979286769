import React from 'react'
import style from './WeHelp.module.scss'

const WeHelp = () => {
	return (
		<div className={style.weHelp}>
			<h1 className={style.title}>Мы поможем</h1>
			<div className={style.row}>
				<ul>
					<li>Найти новых клиентов и рынки сбыта</li>
					<li>Заключить выгодный долгосрочный контракт</li>
				</ul>
				<ul>
					<li>Сократить расходы на рекламу, логистику</li>
					<li>Своевременная оплата, регулярные выплаты каждые 7 дней</li>
				</ul>
			</div>
		</div>
	)
}

export default WeHelp