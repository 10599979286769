import React, {createContext, FC, useContext} from 'react'
import Modal from '../../../../../../Modal/Modal'
import {observer} from 'mobx-react'
import CityList from './CityList'

import styles from './ChooseLocationModal.module.scss'
import StoreContext from '../../../../../../../stores/StoreContext'
import CitySelect from '../../../../../../CitySelect/CitySelect'
import useCookies from 'react-cookie/es6/useCookies'

type ChooseRegionModalProps = {
	state: any,
	setState: any
}

export const ChooseLocationModalContext = createContext<
	{
		state: any,
		setState: any
	}
>({
	state: true,
	setState: () => {}
})

const ChooseLocationModal:FC<ChooseRegionModalProps> = observer(({
	state,
	setState
}) => {
	const [cookies, setCookies] = useCookies()
	const stores = useContext(StoreContext)
	const selectCity = (bx_id: string) => {
		stores?.staticStore.setLocation(bx_id)
		setCookies('BITRIX_INT_ASKARON_GEO_LOCATION_ID', bx_id, {path: '/'})
		setState(false)
	}
	return (
		<ChooseLocationModalContext.Provider value={{state, setState}}>
			<Modal
				typeHeader='classic'
				setState={setState}
				state={state}
				title='Выберите свой регион, чтобы увидеть возможные варианты доставки'
				className={styles.cityes}>
				<CitySelect selectedHandle={selectCity} className={styles.cityes__input} label={false}/>
				{stores && <CityList cities={stores.staticStore.favLocation}/>}
			</Modal>
		</ChooseLocationModalContext.Provider>
	)
})

export default ChooseLocationModal