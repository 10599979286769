import React from 'react'
import style from './Delivery.module.scss'
import russia from './iamges/russia.svg'
import truck from './iamges/truck.svg'
import packageImg from './iamges/package.svg'


const Delivery = () => {
    return (
        <div>
            <h4 className={style.title}>Доставка</h4>
            <div className={style.table}>
                <div className={style.table__item}>
                    <img src={russia} alt=""/>
                    <p>Доставку осуществляем транспортной компанией, до любого удобного Вам терминала или до адреса, по всей России.</p>
                </div>
                <div className={style.table__item}>
                    <img src={truck} alt=""/>
                    <p>Работаем под ключ с любой транспортной компанией.</p>
                </div>
                <div className={style.table__item}>
                    <img src={packageImg} alt=""/>
                    <p>Хрупкие грузы тщательно упаковываем, самостоятельно производим обрешетку товара при необходимости.</p>
                </div>
            </div>
        </div>
    )
}

export default Delivery