import React from 'react'
import Banner from './components/Banner/Banner'
import BuyersAndProviders from './components/BuyersAndProviders/BuyersAndProviders'
import Map from './components/Map/Map'
import Quality from './components/Quality/Quality'
import Advantages from './components/Advantages/Advantages'
import HowWorks from './components/HowWorks/HowWorks'

const AboutPage = () => {
    return (
        <>
            <Banner/>
            <div className="container" style={{maxWidth: 1440}}>
                <BuyersAndProviders/>
                <Map/>
                <Quality/>
                <Advantages/>
                <HowWorks/>
            </div>
        </>
    )
}

export default AboutPage