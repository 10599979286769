import React from 'react'
import style from './Quality.module.scss'

const Quality = () => {
	return (
		<div className={style.quality}>
			<h1 className={style.title}>Контроль качества</h1>
			<div className={style.row}>
				<div>
					<p>Мы проверяем наших<br/> поставщиков</p>
				</div>
				<div>
					<p>Гарантируем</p>
					<ul>
						<li>качество продукции</li>
						<li>cвоевременную отгрузку товара</li>
						<li>отсутствие контрафактной продукции</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default Quality