import React from 'react'
import Header from './components/Header/Header'
import Return from './components/Return/Return'
import Faq from './components/Faq/Faq'

const ReturnsPage = () => {
	return (
		<div style={{background: '#fff'}}>
			<div className="container" style={{maxWidth: 1440}}>
				<Header/>
				<Return/>
				<Faq/>
			</div>
		</div>
	)
}

export default ReturnsPage