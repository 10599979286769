import React from 'react'
import Banner from './components/Banner/Banner'
import WeHelp from './components/WeHelp/WeHelp'
import HowToBecomeProvider from './components/HowToBecomeProvider/HowToBecomeProvider'

const PartnersPage = () => {
	return (
		<>
			<Banner/>
			<div className="container" style={{maxWidth: 1440}}>
				<WeHelp/>
				<HowToBecomeProvider/>
			</div>
		</>
	)
}

export default PartnersPage