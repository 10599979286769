import React from 'react'
import LinkItem from './LinkItem/LinkItem'

type LinkListProps = {
    data: {
        code: string
        gallery: []
        id: string
        name: string
        section: string
        text: string
        url: string
    }[]
}

const LinkList = ({data}: LinkListProps) => {
    return (
        <>
            {
                data.map(({url, name}, index) =>
                    <LinkItem key={index} url={url} text={name}/>
                )
            }
        </>
    )
}

export default LinkList
