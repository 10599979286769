import React, {FC} from 'react'
import styles from '../../Search.module.scss'
import {Link} from 'react-router-dom'

type DetailItemProps = {
	id: string
	name: string
	img: string
	url: string
	close: any
}

const DetailItem:FC<DetailItemProps> = ({id, name, img, url, close}) => {
	return (
		<Link className={styles['search__result-item']} to={url} onClick={close}>
			<div className={styles['search__result-img']}>

				{/*FIXME хардкод картинки это плохо, но пока другой нет :(*/}
				<img src='https://i.ibb.co/26yX9bz/generator.png' alt={'img'}/>
			</div>
			<div className={styles['search__result-text']}>{name}</div>
		</Link>
	)
}

export default DetailItem