import React from 'react'
import compareImg from './images/compare.svg'
import networkImg from './images/network.svg'
import pinImg from './images/pin.svg'
import protectionImg from './images/protection.svg'
import securityImg from './images/security.svg'
import style from './Advantages.module.scss'

const Advantages = () => {
	return (
		<div className={style.advantages}>
			<h1>Преимущества</h1>
			<div className={style.grid}>
				<div className={`${style.row} ${style['row--3cols']}`}>
					<div className={`${style.cell} ${style['cell--withLink']}`}>
						<img src={compareImg} alt="" className={style.cell__img}/>
						<p className={style.cell__descr}>Возможность сравнить цены у различных поставщиков и производителей.</p>
						<a href="#" className={style.cell__link}>Перейти в каталог</a>
					</div>
					<div className={`${style.cell} ${style['cell--withLink']}`}>
						<img src={networkImg} alt="" className={style.cell__img}/>
						<p className={style.cell__descr}>Возможность выбора необходимого поставщика, согласно Вашим запросам.</p>
						<a href="#" className={style.cell__link}>Отправить запрос</a>
					</div>
					<div className={style.cell}>
						<img src={pinImg} alt="" className={style.cell__img}/>
						<p className={style.cell__descr}>Отсутствует риск недобросовестных поставщиков. Все риски мы берем на себя.</p>
					</div>
				</div>
				<div className={`${style.row} ${style['row--2cols']}`}>
					<div className={style.cell}>
						<img src={protectionImg} alt="" className={style.cell__img}/>
						<p className={style.cell__descr}>Гибкая логистика. Вы выбираете поставщика находящегося максимально близко к Вашей локации, что влияет цену доставки.</p>
					</div>
					<div className={style.cell}>
						<img src={securityImg} alt="" className={style.cell__img}/>
						<p className={style.cell__descr}>Упаковываем и страхуем грузы. Все хрупкие и маленькие детали тщательно упаковывают и маркируют.</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Advantages