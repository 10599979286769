import {makeAutoObservable} from 'mobx'
import {fetchSellerShipment} from '../api/sellerShipment'

class SellerCreateUpdStore {

	dataTableConfirm = {
		settings: {
			desktop: [''],
			mobile: ['']
		},
		rows: [],
		loading: true
	}

	constructor() {
		makeAutoObservable(this)
	}

	getDocList = async () =>  {
		this.dataTableConfirm.loading = true
		fetchSellerShipment(undefined, undefined, 'N', 'N')
			.then(res => {
				console.log(res)
				if (res.data) {
					this.dataTableConfirm = res.data
				}
			})
			.catch(err => console.log(err))
			.finally(() => this.dataTableConfirm.loading = false)
	}
}

export default SellerCreateUpdStore