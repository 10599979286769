import {makeAutoObservable, runInAction} from 'mobx'
import {fetchCarts} from '../api'
import {fetchCartProducts, setCartProduct} from '../api/basket'

class CartStore {
    cartsCount = 5
	productsInCartCount = 0
    cart = {
        uid: 1,
        name: 'Коризночка номер один'
    }
    dataTable: any = {
        settings: {
            desktop: [],
            mobile: []
        },
        rows: [],
        loading: false
    }

    constructor() {
        makeAutoObservable(this)
    }

    renameCart = (text: string) => {
        runInAction(() => {
            this.cart.name = text
        })
    }

    clearCart = () => {
        this.dataTable = {
            settings: {
                desktop: [''],
                mobile: ['']
            },
            rows: [],
            loading: false
        }
        this.setProductsCount()
    }

    setProductsCount = () => {
        this.productsInCartCount = this.dataTable.rows.reduce((accumulator: any, current: any) => accumulator + current.count.counter.value, 0)
    }

    getCartProducts = async () => {
        await runInAction(() => {
            this.dataTable.loading = true
            fetchCartProducts()
                .then(res => {
                    this.dataTable = res
					this.setProductsCount()
                    this.dataTable.loading = false
                })
                .catch(err => console.log(err))
        })
    }

    getCarts = async () => {
        await runInAction(() => {
            this.dataTable.loading = true
            fetchCarts()
                .then(res => {
                    this.dataTable = res
                    this.dataTable.loading = false
                })
                .catch(err => console.log(err))
        })
    }

    delete = (uid: number) => {
        if (!uid) {
            return null
        }
        setCartProduct(uid, 0)
            .then((r) => this.dataTable = r)
    }


}

export default CartStore
