import React from 'react'
import {Redirect, Route, RouteProps, useLocation} from 'react-router-dom'

interface GuardedRouteProps extends RouteProps {
	auth: boolean
}

const GuardedRoute = ({component, auth, ...rest}: GuardedRouteProps) => {
	const location = useLocation()
	return (
		<Route {...rest} render={(props => (
			auth
				? React.createElement(component as any, props)
					: <Redirect to={{
						pathname: '/auth',
						state: location.pathname
					}}/>
			))
		}/>
	)
}

export default GuardedRoute