import React from 'react'
import style from './BuyersAndProviders.module.scss'
import {Link} from 'react-router-dom'

const BuyersAndProviders = () => {
	return (
		<div className={style.buyersAndProviders}>
			<div className={style.buyersAndProviders__row}>
				<div className={style.buyersAndProviders__buyer}>
					<p className={style.buyersAndProviders__title}>Покупателям</p>
					<p>Произведем персональный подбор автозапчасти в реальном времени</p>
					<p>Гарантируем доставку Вашего товара в любую точку России</p>
					<p>Оперативный ответ по всем возникающим вопросам</p>
					<div className={style.buyersAndProviders__btns}>
						<Link to={'/catalog/'} className={`${style.buyersAndProviders__btn} ${style['buyersAndProviders__btn--black']}`}>
							Перейти в каталог
						</Link>
						<a href={'#'} className={`${style.buyersAndProviders__btn} ${style['buyersAndProviders__btn--arrow']}`}>
							Подобрать деталь?
						</a>
					</div>
				</div>
				<div className={style.buyersAndProviders__provider}>
					<p className={style.buyersAndProviders__title}>Поставщикам</p>
					<p>Расширение рынка сбыта и поиск новых клиентов</p>
					<p>Сократим расходы на рекламу и логистику</p>
					<p>Заключаем долгосрочные выгодные контракты</p>
					<div className={style.buyersAndProviders__btns}>
						<a href={'#'} className={style.buyersAndProviders__btn}>
							Зарегистрироваться
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BuyersAndProviders