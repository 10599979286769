import axios from 'axios'
import {generateFormData} from '../common/utils'

type TypeRequest = {
    method: 'POST' | 'GET'
    url: string
    data?: FormData
    params?: any
}

export const request = async (method: 'POST' | 'GET', url: string, data?: any) => {
    const config: TypeRequest = {
        method,
        url
    }
    if (method === 'POST') {
        config.data = generateFormData(data)
    }
    if (method === 'GET') {
        config.params = data
    }
    const result = await axios(config)
    const response = result.data
    if (response.errors.length) {
        throw new Error(response.errors[0].message)
    }
    return response
}

// получаем список документов
export const fetchDocuments = async () => {
    try {
        const response = await axios.get('https://run.mocky.io/v3/5dc42bb0-06a1-4d05-b526-772239c0784e')
        return response.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

// получаем список заказов
export const fetchOrders = async () => {
    try {
        const response = await axios.get('https://run.mocky.io/v3/39bc0900-2b34-4e27-8ae3-9abb42eabcf6')
        return response.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

// получаем список деталей
export const fetchDetails = async () => {
    try {
        const response = await axios.get('https://run.mocky.io/v3/75c0f9ea-eaec-4362-8cbe-0cca1c4d24a1')
        return response.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

// получаем список возвратов
export const fetchReturns = async () => {
    try {
        const response = await axios.get('https://run.mocky.io/v3/c90e1dc3-dcd1-4446-ac55-afa22d9904e4')
        return response.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

// получаем список возвратов
export const fetchReturnRequest = async () => {
    try {
        const response = await axios.get('https://run.mocky.io/v3/273a092e-5ee1-4949-be03-ca4a10efe98b')
        return response.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

// получаем список корзин пользователя
export const fetchCarts = async () => {
    try {
        const response = await axios.get('https://run.mocky.io/v3/86d3ef04-c0f4-4f81-b7cf-b4295775aeb8')
        return response.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

// получаем список пользователей в профиле
export const fetchManagers = async () => {
    try {
        const response = await axios.get('/api/user/managers')
        return response.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

// список заказов в кабинете поставщика
// export const fetchSellerOrders = async () => {
//     try {
//         const response = await axios.get('https://run.mocky.io/v3/d4193199-1dd5-461f-8911-f68182189702')
//         return response.data
//     } catch (err) {
//         console.log(err)
//         throw err
//     }
// }

// детальный заказ в кабинете поставщика
export const fetchSellerOrder = async () => {
    try {
        const response = await axios.get('https://run.mocky.io/v3/fb8d7fb9-c877-4fc8-add1-ab515ebfab0c')
        return response.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

// детальный подтвержденный заказ в кабинете поставщика
export const fetchSellerOrderConfirmed = async () => {
    try {
        const response = await axios.get('https://run.mocky.io/v3/70885baf-6018-4544-96dd-3bb22461eedb')
        return response.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

// детальный частично подтвержденный заказ в кабинете поставщика
export const fetchSellerOrderPartlyConfirmed = async () => {
    try {
        const response = await axios.get('https://run.mocky.io/v3/74c8a954-ec5d-4b32-b249-440b969e3531')
        return response.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

// список найденых деталей в автокомплите
export const fetchSearchedCategories = async () => {
    try {
        const response = await axios.get('https://run.mocky.io/v3/9cc88543-7e3f-402c-b55e-fd7084e356b4')
        return response.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

// список найденых деталей в таблице
export const fetchSearchResults = async () => {
    try {
        const response = await axios.get('https://run.mocky.io/v3/7fc9908a-e100-4104-ba8d-d0091124951b')
        return response.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

// // список ожидания отгрузки
// export const fetchSellerShipment = async () => {
//     try {
//         const response = await axios.get('https://run.mocky.io/v3/059335a6-557d-40e7-8fcc-c45b61c3e445')
//         return response.data
//     } catch (err) {
//         console.log(err)
//         throw err
//     }
// }

// список заказов на возврат в кабинете поставщика
export const fetchSellerReturnOrders = async () => {
    try {
        const response = await axios.get('https://run.mocky.io/v3/2fd21b8b-a3bd-4743-867f-3dc696b8819e')
        return response.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

// детальный заказ на возврат в кабинете поставщика
export const fetchSellerReturnOrder = async () => {
    try {
        const response = await axios.get('https://run.mocky.io/v3/cdd86eb3-7fed-41d0-abfd-16b2bccf155a')
        return response.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

//споисок ошибок загруженного документа
export const fetchUploadedDocErrors = async () => await axios.get('https://run.mocky.io/v3/61ed91bd-021a-4b15-a55a-190cfd10b297')

//загруженный документ
export const fetchUploadedDoc = async () => await axios.get('https://run.mocky.io/v3/bb13ed4c-62bf-4f94-8f78-49b1979c01c7')

//список возвратов в кабинете поставщика
export const fetchSellerReturns = async () => await axios.get('https://run.mocky.io/v3/e85eb03a-35ff-4299-a817-fc531139e0d3')

//детальный возврат в кабинете поставщика
export const fetchSellerReturn = async () => await axios.get('https://run.mocky.io/v3/98314984-18f5-4931-ae8e-f56e747f4366')

