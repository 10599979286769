import React from 'react'
import style from './Banner.module.scss'
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs'

const Banner = () => {

    const breadCrumbItems = [
        {
            'title': 'Главная',
            'path': '/'
        },
        {
            'title': 'О компании',
            'path': '/about'
        }
    ]

    return (
        <div className={style.banner}>
            <div className={style.breadcrumbs}>
                <Breadcrumbs items={breadCrumbItems}/>
            </div>
            <div className={`${style.wrapper} container`}>
                <div className={style.info}>
                    <h1 className={style.info__title}>Большой выбор автозапчастей со всего мира</h1>
                    <div className={style.statistics}>
                        <div className={style.statistics__title}>400 тысяч</div>
                        <div className={style.statistics__description}>запчастей <span className={style.textOutline}>в наличии</span></div>
                    </div>
                    <div className={style.statistics}>
                        <div className={style.statistics__title}>Более 50 миллионов </div>
                        <div className={style.statistics__description}>наименований со всех точек страны</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner