import React from 'react'
import style from './Map.module.scss'
import mapImage from './images/map.jpg'

const Map = () => {
	return (
		<div className={style.content}>
			<h1>Поставщики со всей России<br/> на одной площадке</h1>
			<p className={style.description}>Мы соединяем поставщиков со Всей России</p>
			<p className={style.subtitle}>&gt; 100 000 поставщиков</p>
			<div className={style.map}>
				<img src={mapImage} alt="Карта"/>
			</div>
		</div>
	)
}

export default Map