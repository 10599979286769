import React from 'react'
import style from './Payment.module.scss'
import card from './images/card.svg'
import sbp from './images/sbp.svg'
import bank from './images/bank.svg'
import banknotes from './images/banknotes.svg'
import {Link} from 'react-router-dom'

const Payment = () => {
    return (
        <div>
            <h4 className={style.title}>Способы оплаты</h4>
            <div className={style.table}>
                <div className={style.table__row}>
                    <div className={style.table__name}>
                        <img src={card} alt=""/>
                        <span>Банковской картой</span>
                    </div>
                    <div className={style.table__info}>
                        <p>Оплачивайте банковской картой на сайте. Оплата происходит безопасным способом. Мы
                            не имеем доступа к Вашим данным, все платежи проходят через банковскую систему.</p>
                        <p>После оплаты заказа вы получите электронный чек. Все электронные чеки можно скачать
                            в <Link to="/personal" style={{color: '#EE5E20'}}>личном кабинете</Link>.</p>
                    </div>
                </div>
                <div className={style.table__row}>
                    <div className={style.table__name}>
                        <img src={sbp} alt=""/>
                        <span>Система быстрых платежей</span>
                    </div>
                    <div className={style.table__info}>
                        <p>Принимаем оплату по системе быстрых платежей. Это быстрый и безопасный способ произвести
                            оплату.</p>
                    </div>
                </div>
                <div className={style.table__row}>
                    <div className={style.table__name}>
                        <img src={banknotes} alt=""/>
                        <span>Наличными</span>
                    </div>
                    <div className={style.table__info}>
                        <p>Заказ можно оплатить наличными курьеру. При оплате вам выдадут чек, платеж отразим в личном
                            кабинете.</p>
                    </div>
                </div>
                <div className={style.table__row}>
                    <div className={style.table__name}>
                        <img src={bank} alt=""/>
                        <span>По реквизитам</span>
                    </div>
                    <div className={style.table__info}>
                        <p>Оплата по реквизитам удобный способ оплаты, если Вам не доступны остальные варианты
                            оплат.</p>
                        <p>Оплата по безналичному расчету — единственный доступный способ оплаты для юридических лиц.
                            Частные покупатели так же могут оплатить заказ банковским переводом. Счет выставляется
                            автоматически и отправляется на ваш адрес электронной почты. Счет так же доступен в Вашем
                            личном кабинете.</p>
                        <p>Оплатить счет можно в любом банке. Банк взимает комиссию за перевод 3-7% от стоимости заказа.
                            Срок зачисления денежных средств — 1-2 рабочих дня. Формирование заказа начнется после
                            поступления денег на счет компании.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Payment