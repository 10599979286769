import {makeAutoObservable, runInAction} from 'mobx'
import {fetchSellerShipment} from '../api/sellerShipment'

class SellerShipmentStore {

	dataTable = {
		settings: {
			desktop: [''],
			mobile: ['']
		},
		nav: {
			count: 0,
			page: 0,
			size: 0,
		},
		rows: [],
		loading: true
	}

	pagination = {
		page: 0,
		limit: 30,
		size: 400
	}
	errors = []
	paginationSize = 20

	constructor() {
		makeAutoObservable(this)
	}

	get paginationSelect() {
		const options = ['5', '20']
		const perPage = options.findIndex(item => parseInt(item) === this.paginationSize)
		return {
			perPage,
			options
		}
	}

	clearDataTable = () => {
		this.dataTable = {
			settings: {
				desktop: [''],
				mobile: ['']
			},
			nav: {
				count: 0,
				page: 0,
				size: 0,
			},
			rows: [],
			loading: true
		}
	}

	getShipment = async (page?: number) => {
		this.errors = []
		this.dataTable.loading = true
		fetchSellerShipment(page, this.paginationSize)
			.then(res => {
				console.log(res.data)
				if (res.data) {
					this.dataTable = res.data
				}
				else {
					this.clearDataTable()
					this.errors = res.errors
				}
				this.dataTable.loading = false
			})
			.catch(err => console.log(err))
	}
}

export default SellerShipmentStore