import React from 'react'
import style from './HowWorks.module.scss'

const HowWorks = () => {
	return (
		<div className={style.how}>
			<h1>Как это работает</h1>
			<div className={style.how__stepper}>
				<div className={style.how__step}>
					<p className={style.how__stepLeft}>Вы делаете заказ на сайте. В личном кабинете можно отслеживать статус его выполнения</p>
					<div className={style.how__stepNumber}>
						<span>1</span>
					</div>
				</div>
				<div className={style.how__step}>
					<div className={style.how__stepNumber}>
						<span>2</span>
					</div>
					<p className={style.how__stepRight}>Мы сортируем и комплектуем заказы, проверяем качество товаров</p>
				</div>
				<div className={style.how__step}>
					<p className={style.how__stepLeft}>Поставщик собирает ваш заказ и отправляет на Наш склад</p>
					<div className={style.how__stepNumber}>
						<span>3</span>
					</div>
				</div>
				<div className={style.how__step}>
					<div className={style.how__stepNumber}>
						<span>4</span>
					</div>
					<p className={style.how__stepRight}>Готовый заказ отправляем транспортной компанией</p>
				</div>
				<div className={style.how__step}>
					<p className={style.how__stepLeft}>Когда товар приходит в Ваш город — мы сообщаем Вам</p>
					<div className={`${style.how__stepNumber} ${style['how__stepNumber--done']}`}>
						<span/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default HowWorks