import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import IconArrow from '../../../../Svg/IconArrow'
import PopoverMenu from '../../../../PopoverMenu/PopoverMenu'
// import StoreContext from '../../../../../stores/StoreContext'
import {observer} from 'mobx-react'

const ReturnsMenu = observer(({urlUserRole}: {urlUserRole: string}) => {
	const [menuReturnsShowing, setMenuReturnsShowing] = useState(false)
	// const stores = useContext(StoreContext)
	const menuReturns = [
		{
			title: 'Заявка на возврат',
			url: `/${urlUserRole}/returns/create`
		},
		{
			title: 'Возвраты',
			url: `/${urlUserRole}/returns`
		}
	]
	return (
		<div
			className='header__addit-item refunds'
			onMouseOver={(e) => setMenuReturnsShowing(true)}
			onMouseLeave={(e) => setMenuReturnsShowing(false)}
		>
			<Link to={'#'} className='refunds__link' >
				<div className='refunds__link-text'>Возвраты</div>
				<IconArrow className='refunds__link-icon' />
			</Link>
			{menuReturnsShowing ? <PopoverMenu data={menuReturns} position={'center'}/> : null}
		</div>
	)
})

export default ReturnsMenu