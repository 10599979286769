import axios from 'axios'

// список заказов продавца
export const fetchSellerOrders = async (page?: number, size?: number) => {
	try {
		const result = await axios.get('/api/provider/list', {
			params: {
				page,
				size
			}
		})
		return result.data
	} catch (e) {
		console.log(e)
	}
}

// детальный заказ продавца
export const fetchSellerOrder = async (id: string) => {
	try {
		const result = await axios.get('/api/provider/detail', {
			params: {
				id
			}
		})
		return result.data
	}
	catch (e) {
		console.log(e)
	}
}

//подтверждение заказа
export const sellerConfirmOrder = async (formData: FormData) => {
	try {
		const result = await axios.post('/api/provider/confirm', formData)
		return result.data
	}
	catch (e) {
		console.log(e)
	}
}

//разрешить подтверждение/отклонение заказа для продавца
//только для разработчиков!!
export const sellerAllowOrderProcess = async (id: string) => {
	try {
		const result = await axios.get('/api/provider/allowProcess', {
			params: {
				id
			}
		})
		return result.data
	}
	catch (e) {
		console.log(e)
	}
}