import {makeAutoObservable} from 'mobx'
import {fetchOrder} from '../api/order'
import {replaceIsoDatesInString} from '../common/utils'

type TGeneralInfo = {
    label: string,
    info: {
        text: string,
        status?: string
    }
}[]

class OrderStore {
    errors = []
    generalInfo: TGeneralInfo = []
    dataTable = {
        settings: {
            desktop: [''],
            mobile: ['']
        },
        rows: [],
        totals: {} as {inorder: any, rejected: any, total: any},
        loading: true
    }
    payBtnLink = ''

    constructor() {
        makeAutoObservable(this)
    }

    get totalSum() {
        return this.dataTable.totals.total.summ || 'подсчёт...'
    }

    parseGeneralInfo = (generalInfoObj: TGeneralInfo): TGeneralInfo => {
        return generalInfoObj.map(({label, info}) => {
            return {
                label,
                info: {
                    text: replaceIsoDatesInString(info.text || ''),
                    status: info.status
                }
            }
        })
    }

    clearDataTable = () => {
        this.dataTable = {
            settings: {
                desktop: [''],
                mobile: ['']
            },
            rows: [],
            totals: {} as {inorder: any, rejected: any, total: any},
            loading: true
        }
    }

    getOrder = async (id: string) => {
        this.errors = []
		this.dataTable.loading = true
        fetchOrder(id)
            .then(res => {
                console.log(res)
                if (res.data) {
                    const {generalInfo, payBtn, ...rest} = res.data
                    this.generalInfo = this.parseGeneralInfo(generalInfo)
                    this.payBtnLink = payBtn
                    this.dataTable = rest
                }
                else {
                    this.clearDataTable()
                    this.errors = res.errors
                }
				this.dataTable.loading = false
            })
            .catch(err => {
                console.log(err)
            })
    }
}

export default OrderStore
