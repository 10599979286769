import React, {useContext} from 'react'
import {CitySelectContext} from './CitySelect'

type CityItemProps = {
	cityObj: any
	setSelectValue?: (x:string) => void
}

const CityItem = ({cityObj, setSelectValue}: CityItemProps) => {
	const {onSelect, styles} = useContext(CitySelectContext)

	const handleMouseDown = (cityObj: any) => {
		onSelect(cityObj)
		console.log(cityObj)
		if (setSelectValue) setSelectValue(cityObj.value)
	}

	return (
		<div className={styles['citySelect__result-item']} onMouseDown={() => handleMouseDown(cityObj)}>
			<div className={styles['citySelect__result-text']}>{cityObj.value}</div>
		</div>
	)
}

export default CityItem