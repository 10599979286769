import React, {FC, useContext} from 'react'
import styles from './ChooseLocationModal.module.scss'
import StoreContext from '../../../../../../../stores/StoreContext'
import {ChooseLocationModalContext} from './ChooseLocationModal'

type CityItemProps = {
	city: {
		value: string,
		unrestricted_value: string,
		data: {
			bx_id: string,
			bx_code: string,
			postal_code: string,
			price: 'Y' | 'N'
		}
	}
}

const CityItem:FC<CityItemProps> = ({city}) => {
	const stores = useContext(StoreContext)
	const {setState} = useContext(ChooseLocationModalContext)
	const changeLocation = () => {
		if (stores) {
			stores.staticStore.setLocation(city.data.bx_id)
			setState(false)
		}
	}
	return (
		<div className={styles.cityes__item} onClick={changeLocation}>{city.value}</div>
	)
}

export default CityItem