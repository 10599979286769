import React from 'react'
import style from './HowToBecomeProvider.module.scss'

const HowToBecomeProvider = () => {
	return (
		<div className={style.how}>
			<h1>Как стать нашим поставщиком</h1>
			<div className={style.how__stepper}>
				<div className={style.how__step}>
					<p className={style.how__stepLeft}>
						<p>Заполните заявку</p>
						<p className={style.orange}>
							<a href="#">Перйти в регистрацию поставщика</a>
						</p>
					</p>
					<div className={style.how__stepNumber}>
						<span>1</span>
					</div>
				</div>
				<div className={style.how__step}>
					<div className={style.how__stepNumber}>
						<span>2</span>
					</div>
					<p className={style.how__stepRight}>С Вами свяжется наш  менеджер. Вы сможете обсудить ассортимент, цены и условия поставки</p>
				</div>
				<div className={style.how__step}>
					<p className={style.how__stepLeft}>Подготовьте товар согласно нашему заказу Мы сами высылаем заказы на закупку
						в Вашем личном кабинете поставщика</p>
					<div className={style.how__stepNumber}>
						<span>3</span>
					</div>
				</div>
				<div className={style.how__step}>
					<div className={style.how__stepNumber}>
						<span>4</span>
					</div>
					<p className={style.how__stepRight}>Получите свой первый платёж.
						Cрок платежа зависит от согласованной отсрочки</p>
				</div>
				<div className={style.how__step}>
					<p className={style.how__stepLeft}>Отслеживайте результаты в личном кабинете поставщика</p>
					<div className={`${style.how__stepNumber} ${style['how__stepNumber--done']}`}>
						<span/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default HowToBecomeProvider