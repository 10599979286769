import axios from 'axios'

// список ожидания отгрузки
export const fetchSellerShipment = async (page?: number, size?: number, approved?: string, inUpdDoc?: string) => {
	try {
		const response = await axios.get('/api/provider/shipments', {
			params: {
				page,
				size,
				approved,
				inUpdDoc
			}
		})
		return response.data
	} catch (err) {
		console.log(err)
		throw err
	}
}